
.article-title {
    margin-bottom: 8px;

    font-family: 'Amazon Ember';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #0073BB;
}

.article-date {
    margin-bottom: 8px;

    font-family: 'Amazon Ember';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #8F8F8F;
}

.article-overview {
    margin-bottom: 30px;

    font-family: 'Amazon Ember';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #525B65;

    &.landing-page {
        font-size: 12px;
        line-height: 14px;
        color: #4C4C4C;

        // Only show first line of article-overview. Taken from https://tailwindcss.com/docs/text-overflow
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}