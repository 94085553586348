@import "../../../common/styles/variables.scss";

.kb-footer {
  background-color: $midnight-color;
  width: 100%;
  position: sticky;
  
  .footer-content {
    @include max-width-centered(1024px);
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    flex-direction: row;
    font-family: $amazon-regular-font;
    font-size: 12px;
    line-height: 14px;
  }

  a {
    color: white;
    text-decoration: none;
    cursor: pointer;
    padding-top: 8px;
    padding-left: 43px;
  }
}
