// Common styles and style overrides.
// Look for examples here:
// https://code.amazon.com/packages/OmniaItemsModule/blobs/mainline/--/src/common/styles.scss
// https://code.amazon.com/packages/OmniaWebApp/blobs/mainline/--/src/commn/styles.scss
@import "variables.scss";

// Polaris Style OVERRIDES
.awsui {
    [class*="awsui_content_"] {
        margin: auto;
    }

    [class*="awsui_layout-main"] {
        background-color: white !important;
    }
}

body {
    font-family:$amazon-regular-font;
}

.loading-screen-body {
    position: absolute !important;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loading-screen-modal .modal-content{
    background: rgba(255, 255, 255, .5) !important;
}

// overriding the `top` value from the TableOfContents widget in LegoWidgetsConfig-ITPortal
// so that the header doesn't overlap the table of contents
.lego-page-content #table-of-contents {
    top: $header-height + 10px;

    // extra 48px for expanded header on small displays
    @media (max-width: 912px) {
        top: $header-height + 48px + 10px;
    }
}

:target {
    scroll-margin-top: $header-height + 32px;
}
