@import "../../../common/styles/variables.scss";

$max-width: 312px;

#search-bar {
  display: block;
}

#search-bar input {
  background: white;
  background-image: url(../../../../public/img/search.svg);
  background-size: 16px;
  background-position: 18px;
  background-repeat: no-repeat;

  height: 40px;
  width: $max-width;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  border: none;

  cursor: pointer;
  font-size: 14px;
  font-family: $amazon-regular-font;
  letter-spacing: 0;
  color: $black-russian-color;
  text-indent: 46px;

  @media (max-width: 912px) {
    width: 100%;
  }
}

#search-suggestions {
  display: block;
  position: absolute;
  z-index: 99;
  width: $max-width;
  height: auto;
  margin-top: 8px;
  background: white;

  box-shadow: 0px 2px 10px rgba(50, 50, 50, 0.5);
  border-radius: 4px;
  border: none;

  @media (max-width: 912px) {
    width: 90%;
  }
}

#search-suggestions a {
  color: rgb(40, 40, 40);
  height: 40px;
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#search-suggestions a:link {
  text-decoration: none;
}

#search-suggestions a:hover {
  background-color: rgba(35, 47, 62, 0.1);
}

.visible {
  display: block;
}

.invisible {
  display: none;
}
