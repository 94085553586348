@import "../../../common/styles/variables.scss";
@import "../../../modules/components/LandingPageArticle/style.scss"; // for link colours, etc

.search-result-list {
  @include max-width-centered($max-width);

  padding: 33px;
  padding-bottom: 19px;
  margin-bottom: 50px;
  margin-top: 16px;
  padding-top: 26px;
  background: white;
  box-shadow: 0px 2px 15px rgba(0, 70, 80, 0.1);

  .search-result-item {
    width: 100%;

    & + .search-result-item {
      border-top-color: rgb(230, 230, 230);
      border-top-style: solid;
      border-top-width: 1px;
      padding-top: 1rem;
    }
  }

  .search-result-title {
    margin-bottom: 8px;

    font-family: "Amazon Ember";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #0073bb;
  }

  .search-result-overview {
    // Only show first line of article-overview. Taken from https://tailwindcss.com/docs/text-overflow
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
