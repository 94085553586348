@import "../../../common/styles/variables.scss";


.sticky {
  top:0px;
  position: sticky;
  z-index: 2;
}

.regular-header {
  background-color: $midnight-color;

  #kbLogo {
    cursor: pointer;

    @media (max-width: 301px) {
      width: 100%;
    }
  }

  .header-content {
    @include max-width-centered($max-width);
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: space-between;
    min-height: $header-height;

    #header-buttons {
      display: flex;
      align-items: center;
    }

    a.header-button {
      padding: 8px 16px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 20.5px;
      color: #FFFFFF;
      font-size: 14px;
      text-decoration: none;
    }

    a.header-button:hover {
      color: #FFFFFF;
      background: rgba(255, 255, 255, 0.2);
    }

    @media (max-width: 465px) {
      #header-buttons {
        display: none;
      }
    }

    @media (max-width: 912px) {
      margin: 0 5%;

      #search-bar {
        margin: 1rem auto;
        order: 3;
        width: 100%;
      }
    }
  }
}

.expanded-header {
  background-color: $midnight-color;
  padding-bottom: 31px;

.expanded-header-content {
  @include max-width-centered($max-width);

  @media (max-width: 912px) {
    margin: 0 5%;
  }

  #search-bar {
    $search-width: 612px;

    input {
      height: 53px;
      width: 100%;
      max-width: $search-width;
      border-radius: 50px;
      border: none;
      margin-top: 14px;
    }

    #search-suggestions {
      width: 90%;
      max-width: $search-width;
      border-radius: 12px;
      border: none;
    }
  }

  .header-logo {
    align-items: center;
    min-height: $header-height;
    display: flex;
  }

  .welcome-text {
    padding-top: 30px;
    font-family: $amazon-light-font;
    font-weight: normal;
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
    color: #ffffff;
    span {
      font-weight: 700;
      font-family: $amazon-regular-font;
    }
  }

  .welcome-sub-text {
    margin-top: 5px;
    margin-bottom: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #ffffff;
  }
}
}
