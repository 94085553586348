@import "common/styles/variables.scss";
@import "modules/components/LandingPageArticle/style.scss"; // for link colours, etc

.sitemap {
  @include max-width-centered($max-width);

  background: white;
  box-shadow: 0px 2px 15px rgba(0, 70, 80, 0.1);
  padding: 26px 33px 19px;

  .categories-container {
    @media screen and (min-width: 768px) {
      column-count: 2;
      column-gap: 1rem;
    }

    .category-item {
      display: inline-block;
      width: 100%;
      box-sizing: border-box;

      li {
        list-style-type: none;
        margin-top: 1rem;
      }
    }
  }
}
