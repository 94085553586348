.user-photo {
    object-fit: cover;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 18px;
    padding-right: 18px;
}