@import "../ArticlesByDate/style.scss";

.article-item {
  header {
    margin-bottom: 1rem;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}