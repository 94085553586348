@import "../../../common/styles/variables.scss";
@import "../../../modules/components/LandingPageArticle/style.scss"; // for link colours, etc

.article-list {
  @include max-width-centered($max-width);

  padding: 33px;
  padding-bottom: 19px;
  margin-bottom: 50px;
  margin-top: 16px;
  padding-top: 26px;
  background: white;
  box-shadow: 0px 2px 15px rgba(0, 70, 80, 0.1);

  .article-item {
    width: 100%;

    & + .article-item {
      border-top-color: rgb(230, 230, 230);
      border-top-style: solid;
      border-top-width: 1px;
      padding-top: 1rem;
    }
  }
}
